import * as types from '../workflows.types';
import client from 'utils/workflowClient';
import Router from 'next/router';
import { attachExecution, extractStatus, extractExecutionStatus } from './util';

export const getWorkflows = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_WORKFLOWS_REQUEST
    });

    try {
      const { data = {} } = await client.get('/workflows');
      const { workflow_designs: workflows = [] } = data;
      workflows.forEach(attachEmptyExecution);
      const statuses = extractStatus(workflows);
      return dispatch({
        type: types.GET_WORKFLOWS_SUCCESS,
        payload: {
          workflows
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_WORKFLOWS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getWorkflow = ({ workflowId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_WORKFLOW_REQUEST
    });

    try {
      const { data = {} } = await client.get(`/workflows/${workflowId}`);
      const { workflow_design: workflow = {} } = data;

      return dispatch({
        type: types.GET_WORKFLOW_SUCCESS,
        payload: {
          workflow,
          executions: extractExecutionStatus(workflow.executions)
        }
      });
    } catch (err) {
      const errorMessage =
        err.response.status === 403
          ? 'You are not allowed to do this action. If you think you should have access, please contact support@quanticoenergy.com'
          : err.message;
      return dispatch({
        type: types.GET_WORKFLOW_FAILURE,
        payload: {
          error: errorMessage
        }
      });
    }
  };
};

export const createWorkflow = ({ name, teamId, template, product }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_WORKFLOW_REQUEST
    });

    try {
      const { data = {} } = await client.post('/workflows', {
        name,
        team_id: teamId,
        template,
        product
      });

      const { workflow_design: workflow = {} } = data;
      attachEmptyExecution(workflow);
      return dispatch({
        type: types.CREATE_WORKFLOW_SUCCESS,
        payload: {
          workflow
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_WORKFLOW_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updateWorkflow = ({ workflowId, name }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_WORKFLOW_REQUEST
    });

    const {
      list: { workflows = [] }
    } = getState().workflows;

    try {
      const { data = {} } = await client.post(`/workflows/${workflowId}`, {
        name
      });

      const { design: workflow = {} } = data;
      attachEmptyExecution(workflow);
      const updatedWorkflows = workflows.map((o) => {
        if (`${o.id}` !== `${workflowId}`) return o;
        return workflow;
      });

      return dispatch({
        type: types.UPDATE_WORKFLOW_SUCCESS,
        payload: {
          current: updatedWorkflows.find((w) => w.id == workflowId),
          workflows: updatedWorkflows
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_WORKFLOW_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const deleteWorkflow = ({ workflowId, deleted }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_WORKFLOW_REQUEST
    });

    const {
      list: { workflows = [] }
    } = getState().workflows;

    try {
      const success = await client.post(`/workflows/${workflowId}`, {
        deleted
      });

      const updatedWorkflows = workflows.filter((o) => {
        if (`${o.id}` !== `${workflowId}`) return o;
      });

      return dispatch({
        type: types.DELETE_WORKFLOW_SUCCESS,
        payload: {
          workflows: updatedWorkflows
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DELETE_WORKFLOW_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const duplicateWorkflow = ({ workflowId, name }) => {
  return async (dispatch) => {
    dispatch({
      type: types.DUPLICATE_WORKFLOW_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/workflows/${workflowId}/duplicate`, { name });
      const { workflow_design: workflow = {} } = data;

      return dispatch({
        type: types.DUPLICATE_WORKFLOW_SUCCESS,
        payload: {
          workflow: workflow,
          executions: extractExecutionStatus(workflow.executions)
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DUPLICATE_WORKFLOW_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const pauseWorkflow = ({ workflowId, runId }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PAUSE_WORKFLOW_REQUEST
    });

    const {
      list: { workflows = [] }
    } = getState().workflows;

    try {
      const { data = {} } = await client.post(`/executions/${runId}/pause`);
      const { workflow_execution = {} } = data;
      return dispatch({
        type: types.PAUSE_WORKFLOW_SUCCESS,
        payload: {
          executions: extractExecutionStatus([workflow_execution])
        }
      });
    } catch (err) {
      return dispatch({
        type: types.PAUSE_WORKFLOW_FAILURE,
        payload: {
          error: "Couldn't pause the workflow."
        }
      });
    }
  };
};

export const stopWorkflow = ({ workflowId, runId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.STOP_WORKFLOW_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/executions/${runId}/stop`);
      const { workflow_execution = {} } = data;

      return dispatch({
        type: types.STOP_WORKFLOW_SUCCESS,
        payload: {
          executions: extractExecutionStatus([workflow_execution])
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.STOP_WORKFLOW_FAILURE,
        payload: {
          error: "Couldn't stop the workflow."
        }
      });
    }
  };
};

export const startWorkflow = ({ workflowId, qearthVersion, reusePreviousExecutions = true }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.START_WORKFLOW_REQUEST
    });

    try {
      const payload = {
        reuse_previous_executions: reusePreviousExecutions
      };

      if (qearthVersion) {
        payload.qearth_version = qearthVersion;
      }

      const { data = {} } = await client.post(`/workflows/${workflowId}/prepare_then_start`, payload);
      const { workflow_execution } = data;
      const {
        list: { workflows = [] }
      } = getState().workflows;

      const updatedWorkflows = attachExecution(workflows, workflowId, workflow_execution);
      Router.router.push(
        {
          pathname: Router.router.pathname,
          query: { workflow: workflowId, run: workflow_execution.id }
        },
        undefined,
        { shallow: true }
      );

      return dispatch({
        type: types.START_WORKFLOW_SUCCESS,
        payload: {
          newExecutionId: workflow_execution.id,
          executions: extractExecutionStatus([workflow_execution]),
          updatedWorkflows
        }
      });
    } catch (err) {
      console.log(err);
      let details = '';
      if (err.response?.data?.error) {
        details = `Details: ${err.response.data.error}`;
      }

      const message = `Couldn't start the workflow. ${details}`;
      return dispatch({
        type: types.START_WORKFLOW_FAILURE,
        payload: {
          error: message
        }
      });
    }
  };
};

export const resetDuplication = () => {
  return (dispatch) => {
    dispatch({
      type: types.DUPLICATE_WORKFLOW_RESET
    });
  };
};

function attachEmptyExecution(workflow) {
  if (!workflow.executions || workflow.executions.length === 0) {
    workflow.executions = [
      {
        id: '0',
        workflowId: '0',
        resourceType: 'Run',
        updatedAt: new Date().getTime(),
        status: {
          name: 'CREATED'
        },
        createdAt: new Date().getTime(),
        name: 'v0',
        nodes: [],
        edges: []
      }
    ];
  }
}

export const getDashboardData = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_DASHBOARD_WORKFLOW_REQUEST
    });

    try {
      const { data = {} } = await client.post('/dashboard_data', {});
      const { workflows = [], simulation_runs, workflow_count, training_runs } = data;
      workflows.forEach(attachEmptyExecution);

      const dashboard = {
        simulation_runs,
        workflow_count,
        training_runs
      };

      return dispatch({
        type: types.GET_DASHBOARD_WORKFLOW_SUCCESS,
        payload: {
          workflows,
          dashboard
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_DASHBOARD_WORKFLOW_FAILURE,
        payload: {
          error: (err.response && err.response.data.error) || err.message
        }
      });
    }
  };
};

export const clearCurrentWorkflow = () => {
  return async (dispatch) => {
    return dispatch({
      type: types.CLEAR_CURRENT_WORKFLOW
    });
  };
};
