import * as types from './workflows.types';

export const initialState = {
  list: { name: 'Workflows', workflows: [] },
  nodes: [],
  executions: {},
  current: null,
  edges: [],
  visualizations: {
    files: [],
    charts: []
  },
  logs: {
    uuid: null,
    records: [],
    loading: false
  },
  error: null,
  loading: false,
  outputFiles: [],
  updatedNode: null,
  duplicatedWorkflowId: null,
  dashboard: {}
};

/**
 * Updates node status checking on the createdAt
 * The rationale behind this is that we cannot rely on status
 * coming from the backend in strict order. The backend will send
 * notifications out of order, usually for short-lived nodes.
 */
const mergeStatuses = (current, updated) => {
  if (!updated) {
    return current;
  }

  const merged = { ...current };

  for (var [key, newStatus] of Object.entries(updated)) {
    const currentStatus = current[key];
    var candidate = newStatus;

    if (currentStatus) {
      const previousTS = currentStatus.createdAt || 0;
      const updatedTS = newStatus.createdAt;

      if (updatedTS && updatedTS < previousTS) {
        candidate = currentStatus;
      }
    }

    merged[key] = candidate;
  }

  return merged;
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.DELETE_NODE_REQUEST:
    case types.CREATE_NODE_REQUEST:
    case types.DELETE_EDGE_REQUEST:
    case types.CREATE_EDGE_REQUEST:
    case types.CREATE_WORKFLOW_REQUEST:
    case types.GET_WORKFLOWS_REQUEST:
    case types.START_WORKFLOW_REQUEST:
    case types.UPDATE_WORKFLOW_REQUEST:
    case types.DELETE_WORKFLOW_REQUEST:
    case types.DUPLICATE_WORKFLOW_REQUEST:
    case types.COPY_NODES_EDGES_REQUEST:
    case types.UPDATE_SELECTION_POSITION_REQUEST:
    case types.GET_DASHBOARD_WORKFLOW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        duplicatedWorkflowId: null
      };
    }
    case types.DELETE_NODE_FAILURE:
    case types.CREATE_NODE_FAILURE:
    case types.CLEAR_EDGES_FAILURE:
    case types.CLEAR_NODES_FAILURE:
    case types.DELETE_EDGE_FAILURE:
    case types.CREATE_EDGE_FAILURE:
    case types.GET_EDGES_FAILURE:
    case types.UPDATE_NODE_FAILURE:
    case types.GET_NODES_FAILURE:
    case types.GET_WORKFLOW_FAILURE:
    case types.CREATE_WORKFLOW_FAILURE:
    case types.GET_WORKFLOWS_FAILURE:
    case types.START_WORKFLOW_FAILURE:
    case types.UPDATE_WORKFLOW_FAILURE:
    case types.DELETE_WORKFLOW_FAILURE:
    case types.DUPLICATE_WORKFLOW_FAILURE:
    case types.COPY_NODES_EDGES_FAILURE:
    case types.UPDATE_SELECTION_POSITION_FAILURE:
    case types.GET_DASHBOARD_WORKFLOW_FAILURE: {
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    }

    case types.GET_WORKFLOW_SUCCESS: {
      return {
        ...state,
        current: payload.workflow,
        executions: mergeStatuses(state.executions, payload.executions)
      };
    }
    case types.GET_WORKFLOW_REQUEST: {
      return {
        ...state,
        current: null
      };
    }
    case types.GET_NODES_REQUEST:
    case types.GET_EDGES_REQUEST: {
      return {
        ...state,
        edges: [],
        nodes: [],
        loading: true,
        error: null
      };
    }
    case types.UPDATE_WORKFLOW_SUCCESS:
    case types.DELETE_WORKFLOW_SUCCESS:
    case types.GET_WORKFLOWS_SUCCESS: {
      return {
        ...state,
        list: { ...state.list, workflows: payload.workflows },
        executions: mergeStatuses(state.executions, payload.executions),
        loading: false
      };
    }
    case types.GET_DASHBOARD_WORKFLOW_SUCCESS: {
      return {
        ...state,
        list: { ...state.list, workflows: payload.workflows },
        dashboard: payload.dashboard,
        loading: false
      };
    }
    case types.COPY_NODES_EDGES_SUCCESS:
    case types.UPDATE_SELECTION_POSITION_REQUEST: {
      return {
        ...state,
        list: { ...state.list, workflows: payload.workflows },
        nodes: payload.nodes,
        edges: payload.edges,
        loading: false
      };
    }
    case types.CREATE_WORKFLOW_SUCCESS: {
      return {
        ...state,
        list: { ...state.list, workflows: [...state.list.workflows, payload.workflow] },
        loading: false
      };
    }
    case types.DUPLICATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        list: { ...state.list, workflows: [...state.list.workflows, payload.workflow] },
        executions: mergeStatuses(state.executions, payload.executions),
        loading: false,
        duplicatedWorkflowId: payload.workflow.id
      };
    case types.DUPLICATE_WORKFLOW_RESET:
      return {
        ...state,
        duplicatedWorkflowId: null
      };
    case types.CLEAR_NODES_SUCCESS:
    case types.GET_NODES_SUCCESS: {
      return {
        ...state,
        nodes: payload.nodes,
        executions: mergeStatuses(state.executions, payload.executions),
        loading: false
      };
    }
    case types.UPDATE_NODE_REQUEST: {
      return {
        ...state,
        loading: true,
        isUpdatingNode: true,
        error: null,
        updatedNode: null
      };
    }
    case types.UPDATE_NODE_SUCCESS: {
      return {
        ...state,
        nodes: payload.nodes,
        updatedNode: payload.updatedNode,
        isUpdatingNode: false,
        loading: false
      };
    }
    case types.CLEAR_EDGES_SUCCESS:
    case types.GET_EDGES_SUCCESS: {
      return {
        ...state,
        edges: payload.edges,
        loading: false
      };
    }
    case types.CREATE_EDGE_SUCCESS: {
      return {
        ...state,
        edges: [...state.edges, payload.edge],
        loading: false
      };
    }
    case types.DELETE_EDGE_SUCCESS: {
      return {
        ...state,
        edges: payload.edges,
        loading: false
      };
    }
    case types.CREATE_NODE_SUCCESS: {
      return {
        ...state,
        nodes: [...state.nodes, payload.node],
        current: payload.workflow,
        loading: false
      };
    }
    case types.GET_NODE_VISUALIZATIONS_REQUEST:
      return {
        ...state,
        visualizations: { files: [], charts: [], loading: true },
        loading: true
      };
    case types.GET_NODE_LOGS_REQUEST:
      return {
        ...state,
        logs: { uuid: payload.uuid, records: [], loading: true }
      };
    case types.GET_NODE_VISUALIZATIONS_SUCCESS:
      return {
        ...state,
        visualizations: { ...payload.visualizations, loading: false },
        loading: false
      };
    case types.GET_NODE_LOGS_SUCCESS:
      return {
        ...state,
        logs: { uuid: payload.uuid, records: payload.records, loading: false },
        loading: false
      };
    case types.GET_NODE_VISUALIZATIONS_FAILURE:
      return {
        ...state,
        visualizations: { files: [], charts: [], loading: false },
        loading: false,
        error: payload.error
      };
    case types.GET_NODE_LOGS_FAILURE:
      return {
        ...state,
        logs: { uuid: payload.uuid, records: [], loading: false },
        loading: false,
        error: payload.error
      };
    case types.START_WORKFLOW_SUCCESS:
      return {
        ...state,
        executions: mergeStatuses(state.executions, payload.executions),
        list: { ...state.list, workflows: payload.updatedWorkflows }
      };
    case types.PAUSE_WORKFLOW_SUCCESS:
    case types.STOP_WORKFLOW_SUCCESS:
    case types.UPDATE_WORKFLOW_STATUS_SUCCESS:
      return {
        ...state,
        current: payload.currentWorkflow,
        list: { ...state.list, workflows: payload.workflows }
      };

    case types.UPDATE_NODE_STATUS_SUCCESS:
      return {
        ...state,
        executions: mergeStatuses(state.executions, payload.executions)
      };
    case types.LIST_NODE_OUTPUT_SUCCESS:
      return {
        ...state,
        outputFiles: payload.outputFiles
      };
    case types.DELETE_NODE_SUCCESS:
      return {
        ...state,
        nodes: payload.nodes,
        edges: payload.edges
      };
    case types.CLEAR_CURRENT_WORKFLOW:
      return {
        ...state,
        current: null
      };
    default:
      return state;
  }
}
