import * as types from '../workflows.types';
import client from 'utils/workflowClient';

export const getNodes = ({ workflowId, runId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_NODES_REQUEST
    });

    try {
      const { data = {} } = await client.get(`/workflows/${workflowId}/nodes?execution_id=${runId || 0}`);
      const { nodes = [] } = data;

      return dispatch({
        type: types.GET_NODES_SUCCESS,
        payload: {
          nodes: nodes
        }
      });
    } catch (err) {
      const errorMessage =
        err.response.status === 403
          ? 'You are not allowed to do this action. If you think you should have access, please contact support@quanticoenergy.com'
          : err.message;
      return dispatch({
        type: types.GET_NODES_FAILURE,
        payload: {
          error: errorMessage
        }
      });
    }
  };
};

export const clearNodes = () => {
  return async (dispatch) => {
    try {
      return dispatch({
        type: types.CLEAR_NODES_SUCCESS,
        payload: {
          nodes: []
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CLEAR_NODES_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updateNode = ({ workflowId, runId, nodeId, properties }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_NODE_REQUEST
    });

    try {
      if (properties.data && properties.data.ui) {
        delete properties.data.ui;
      }
      delete properties.isConnectable;

      const { data = {} } = await client.post(`/workflows/${workflowId}/nodes/${nodeId}`, {
        properties
      });

      const { node: updatedNode = {} } = data;
      const { nodes } = getState().workflows;
      const updatedNodes = nodes.map((item) => {
        if (item.id != nodeId) return item;
        return updatedNode;
      });

      return dispatch({
        type: types.UPDATE_NODE_SUCCESS,
        payload: {
          nodes: updatedNodes,
          updatedNode: updatedNode
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_NODE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const createNode = ({ node: newNode, workflowId, position = { x: 0, y: 0 } }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CREATE_NODE_REQUEST
    });
    const { current: currentWorkflow } = getState().workflows;
    try {
      const name = newNode.name;
      const { data = {} } = await client.post(`/workflows/${workflowId}/nodes`, {
        name: name,
        position: position,
        nodeType: newNode.nodeType,
        data: { ...newNode, position: position, label: name, title: name, body: '' }
      });

      const { node = {} } = data;
      const currentNodes = currentWorkflow.nodes || [];
      const updatedWorkflow = { ...currentWorkflow, nodes: [...currentNodes, node] };

      return dispatch({
        type: types.CREATE_NODE_SUCCESS,
        payload: {
          node: node,
          workflow: updatedWorkflow
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_NODE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const deleteNode = ({ id }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_NODE_REQUEST
    });

    const { nodes, edges } = getState().workflows;

    try {
      await client.delete(`/nodes/${id}`);
      return dispatch({
        type: types.DELETE_NODE_SUCCESS,
        payload: {
          nodes: nodes.filter((o) => o.id !== id),
          edges: edges.filter((o) => o.source !== id && o.target !== id)
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.DELETE_NODE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updateSelectionPosition = ({ workflowId, nodes }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_SELECTION_POSITION_REQUEST
    });
    try {
      const { nodes: currentNodes } = getState().workflows;
      const payload = nodes.map((n) => {
        return { id: n.id, position: n.position };
      });
      const { data = {} } = await client.patch(`/workflows/${workflowId}/nodes`, { nodes: payload });
      const { nodes: updatedNodes = [] } = data;

      const updatedMap = {};
      updatedNodes.map((n) => {
        updatedMap[n.id] = n;
      });

      const finalNodes = currentNodes.map((old) => {
        const updated = updatedMap[old.id];
        if (updated) {
          return updated;
        } else {
          return old;
        }
      });

      return dispatch({
        type: types.UPDATE_SELECTION_POSITION_SUCCESS,
        payload: {
          nodes: finalNodes
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_SELECTION_POSITION_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getVisualizations = ({ uuid }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_NODE_VISUALIZATIONS_REQUEST
    });

    if (!uuid)
      return dispatch({
        type: types.GET_NODE_VISUALIZATIONS_SUCCESS,
        payload: {
          visualizations: {
            files: [],
            charts: []
          }
        }
      });

    try {
      const { data } = await client.get(`/nodes/${uuid}/visualization`);
      const { files = [], charts = [] } = data;
      return dispatch({
        type: types.GET_NODE_VISUALIZATIONS_SUCCESS,
        payload: {
          visualizations: {
            files,
            charts
          }
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_NODE_VISUALIZATIONS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getLogs = ({ uuid }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_NODE_LOGS_REQUEST,
      payload: {
        uuid
      }
    });

    if (!uuid)
      return dispatch({
        type: types.GET_NODE_LOGS_SUCCESS,
        payload: {
          uuid,
          records: []
        }
      });

    try {
      const { data } = await client.get(`/nodes/${uuid}/logs`);
      const { logs = [] } = data;

      return dispatch({
        type: types.GET_NODE_LOGS_SUCCESS,
        payload: {
          uuid,
          records: logs
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_NODE_LOGS_FAILURE,
        payload: {
          uuid,
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
